import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Alert } from '@mui/material';
import { AxiosError } from 'axios';
import LoadingScreen from 'components/LoadingScreen';
import { Paths } from 'constants/Paths';
import { useFormik } from 'formik';
import { Invitation, InvitationType } from 'hooks/requests/invitations/invitations.types';
import { useCreateUser } from 'hooks/requests/invitations/useCreateUser';
import { useDoesUsernameExist } from 'hooks/requests/invitations/useDoesUsernameExist';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { UserFormFields } from './types';

const useStyles = makeStyles(() => ({
  action: {
    marginLeft: 'auto',
  },
}));

type CreateUserProps = {
  invitation: Invitation;
  invitationKey: string;
  invitationType: InvitationType;
};

const CreateUser: React.FC<React.PropsWithChildren<CreateUserProps>> = (props) => {
  const { invitationKey, invitationType, invitation } = props;
  const { t } = useTranslation(['global', 'invitations']);
  const history = useHistory();
  const { mutateAsync: createNewUser, isError: createAcitvityGroupError } = useCreateUser(
    invitationType,
    invitation.relation,
  );
  const { mutateAsync: doesUsernameExist, isError: doesUsernameExistError } = useDoesUsernameExist(invitationType);
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>(null);
  const [checkingUsername, setCheckingUsername] = useState(false);

  const emailInputProps = useMemo<Partial<StandardInputProps> | undefined>(() => {
    if (!checkingUsername) {
      return undefined;
    }

    return {
      endAdornment: (
        <InputAdornment position="end">
          <CircularProgress size={14} />
        </InputAdornment>
      ),
    };
  }, [checkingUsername]);
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);

  const doesUsernameExistDebounceRef = useRef<number>(-1);
  const EmployeeFormSchema = useMemo(
    () =>
      Yup.object<UserFormFields>().shape({
        firstname: Yup.string().required(t('global:inputs.entryRequired')),
        lastname: Yup.string().required(t('global:inputs.entryRequired')),
        password: Yup.string()
          .required(t('global:inputs.entryRequired'))
          .test('len', t('global:profileInformation.password.lengthError'), (val) => {
            if (val) return val.length >= 8;
            return false;
          }),
        confirmPassword: Yup.string()
          .required(t('global:inputs.entryRequired'))
          .oneOf([Yup.ref('password')], t('global:profileInformation.confirmPassword.matchError')),
        email: Yup.string()
          .email(t('global:profileInformation.email.errorValidText'))
          .required(t('global:inputs.entryRequired'))
          .test('exists', t('invitations:createUserAlreadyExists'), async (val) => {
            setCheckingUsername(true);
            const debouncedResult = new Promise<boolean>((resolve, reject) => {
              if (doesUsernameExistDebounceRef.current > 0) {
                window.clearTimeout(doesUsernameExistDebounceRef.current);
              }

              doesUsernameExistDebounceRef.current = window.setTimeout(async () => {
                const reqDoesUsernameExist = {
                  username: val ?? '',
                };
                try {
                  const usernameExists = await doesUsernameExist(reqDoesUsernameExist);
                  resolve(usernameExists !== undefined ? usernameExists : false);
                } catch (e) {
                  reject(e);
                }
              }, 1000);
            });

            try {
              const result = await debouncedResult;
              return !result;
            } catch {
              return true;
            } finally {
              setCheckingUsername(false);
            }
          }),
      }),
    [t, doesUsernameExist],
  );
  const handleOnFormikSubmit = useCallback(
    async (fields: UserFormFields) => {
      const { confirmPassword: _, ...formResult } = fields;
      const req = {
        ...formResult,
        username: formResult.email,
        key: invitationKey,
      };

      try {
        await createNewUser(req);
        history.replace(Paths.ACTIVITY_GROUPS.href);
      } catch (e) {
        if ((e as AxiosError).isAxiosError && (e as AxiosError).response?.status === 409) {
          setUserAlreadyExists(true);
        }
      }
    },
    [createNewUser, history, invitationKey],
  );
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      password: '',
      confirmPassword: '',
      email: invitation.email || '',
    },
    isInitialValid: false,
    validationSchema: EmployeeFormSchema,
    onSubmit: handleOnFormikSubmit,
  });

  return (
    <form autoComplete={'off'} onSubmit={formik.handleSubmit} ref={formRef}>
      <Card>
        <CardHeader
          title={t('invitations:createUser')}
          subheader={
            invitationType === 'activityGroup'
              ? t('invitations:group.userintro')
              : t('invitations:relative.relativeintro')
          }
        />
        {userAlreadyExists && (
          <CardContent>
            <Alert severity={'error'} variant={'filled'}>
              {t('invitations:createUserAlreadyExists')}
            </Alert>
          </CardContent>
        )}
        {!userAlreadyExists && createAcitvityGroupError && (
          <CardContent>
            <Alert severity={'error'} variant={'filled'}>
              {t('global:errorSaving')}
            </Alert>
          </CardContent>
        )}
        {doesUsernameExistError && (
          <CardContent>
            <Alert severity={'error'} variant={'filled'}>
              {t('global:errorLoading')}
            </Alert>
          </CardContent>
        )}
        <CardContent>
          {formik.isSubmitting && <LoadingScreen contentLoader />}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                name={'firstname'}
                type={'text'}
                label={t('global:profileInformation.firstname.label')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstname}
                error={!!formik.errors.firstname && formik.touched.firstname}
                helperText={formik.touched.firstname && formik.errors.firstname}
                variant={'outlined'}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={'lastname'}
                type={'text'}
                label={t('global:profileInformation.lastname.label')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastname}
                error={!!formik.errors.lastname && formik.touched.lastname}
                helperText={formik.touched.lastname && formik.errors.lastname}
                variant={'outlined'}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name={'email'}
                type={'text'}
                label={t('global:profileInformation.email.label')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={!!formik.errors.email}
                InputProps={emailInputProps}
                helperText={formik.errors.email}
                variant={'outlined'}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name={'password'}
                type={'password'}
                label={t('global:profileInformation.password.label')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={!!formik.errors.password && formik.touched.password}
                helperText={formik.touched.password && formik.errors.password}
                variant={'outlined'}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name={'confirmPassword'}
                type={'password'}
                label={t('global:profileInformation.confirmPassword.label')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                error={!!formik.errors.confirmPassword && formik.touched.confirmPassword}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                variant={'outlined'}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color={'primary'}
            variant={'outlined'}
            className={classes.action}
            disabled={!formik.isValid}
            type={'submit'}
          >
            {t('invitations:createUserButton')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default CreateUser;
