class StyleConstants {
  static readonly RESIDENT_HEADER_BIG_HEIGHT = 162;
  static readonly MENU_DRAWER_WIDTH = 256;
  static readonly PRIMARY_COLOR = '#24955e';
  static readonly SECONDARY_COLOR = '#5C6D78';
  static readonly PRIMARY_TEXT_COLOR = '#FFFFFF';
  static readonly SECONDARY_TEXT_COLOR = '#000000';
  static readonly RESIDENT_TASK_EVENT_COLOR = '#7795CE';
  static readonly PRIMARY_EVENT_COLOR = '#00A7E7';
  static readonly CREATE_EVENT_COLOR = '#50c8f6';
  static readonly HISTORICAL_EVENT_COLOR = '#E8E8E8';
  static readonly NEXUS_DISPENSED_EVENT_COLOR = '#41BE88';
  static readonly NEXUS_NOT_DISPENSED_EVENT_COLOR = '#00A196';
  static readonly NOACCESS_EVENT_COLOR = '#a6a4a4';
  static readonly CALENDAR_DISABLED_COLOR = '#CCCCCC';
  static readonly BOTTOM_NAVIGATION_HEIGHT = 56;
  static readonly HEADER_BAR_HEIGHT = 64;
  static readonly APP_BAR_HEIGHT = 48;
  static readonly RESIDENT_HEADER_SMALL_HEIGHT = 88;
}

export default StyleConstants;
