export class Toggles {
  static readonly ACTIVITIES = 'Activities';
  static readonly CALENDAR = 'Calendar';
  static readonly CHINA_BOOK = 'ChinaBook';
  static readonly FLUIDS = 'Fluids';
  static readonly HEALTH_MEASUREMENTS = 'HealthMeasurements';
  static readonly IMAGES2 = 'Images2';
  static readonly FILES = 'Files';
  static readonly TASKS_NURSE_REPORT = 'TasksNurseReporting';
  static readonly NURSE_TASKS_FORECASTING = 'NurseTasksForecasting';
  static readonly RECORDS = 'Records';
  static readonly EMPLOYEE_DELEGATION_CODE_TOGGLE = 'EmployeeDelegationCodeToggle';
  static readonly RESIDENT_ACCESS = 'ResidentAccess';
  static readonly PLANS_VUM = 'PlansVUM';
  static readonly NEXUS_PLANNED_MEDICATION = 'NexusPlannedMedication';
  static readonly MEDICINE_ADMINISTRATOR_ROLE = 'MedicineAdministratorRole';
  static readonly KOMBIT_TOGGLE = 'Kombit';
  static readonly EXTERNAL_EMPLOYEE_ID = 'ShowExternalEmployeeId';
  static readonly ANAMNESIS_NURSING_STATUS = 'AnamnesisNursingStatus';
  static readonly INCIDENTS = 'Incidents';
  static readonly AAD_INTEGRATION_TOGGLE = 'AADIntegration';
  static readonly RESIDENT_MEDICINE = 'ResidentMedicine';
  static readonly INSTRUCTIONS = 'Instructions';
  static readonly RECORDS_ACT_REQUIRED = 'RecordsACTRequired';
  static readonly SYSADMIN_REQUIRED_TO_DELETE_RESIDENT = 'SysAdminRequiredToDeleteResident';
  static readonly RESIDENT_RESTRICTING_ACT = 'ResidentRestrictingACT';
  static readonly INVENTORY = 'Inventory';
}
