import React from 'react';
import { AtlasContextClient } from './Clients/AtlasContextClient';
import { NexusContextClient } from './Clients/NexusContextClient';
import { ObservationsContextClient } from './Clients/ObservationsContextClient';
import { TasksContextClient } from './Clients/TasksContextClient';
import { HealthMeasurementsContextClient } from './Clients/HealthMeasurementsContextClient';
import { NotificationsContextClient } from './Clients/NotificationsContextClient';
import { AxiosInstance } from 'axios';
import { RecordsContextClient } from './Clients/RecordsContextClient';
import { PlansContextClient } from './Clients/PlansContextClient';
import { FluidsContextClient } from './Clients/FluidsContextClient';
import { DashboardsContextClient } from './Clients/DashboardsContextClient';
import { ChinabookContextClient } from './Clients/ChinabookContextClient';
import { ReportsContextClient } from './Clients/ReportsContextClient';
import { IncidentsContextClient } from './Clients/IncidentsContextClient';
import { MedicineContextClient } from './Clients/MedicineContextClient';
import { InventoryContextClient } from './Clients/InventoryContextClient';
import { TogglesContextClient } from './Clients/TogglesContextClient';

export interface RestClientsContext {
  atlas?: AtlasContextClient;
  nexus?: NexusContextClient;
  tasks?: TasksContextClient;
  observations?: ObservationsContextClient;
  healthMeasurements?: HealthMeasurementsContextClient;
  notifications?: NotificationsContextClient;
  records?: RecordsContextClient;
  compass?: AxiosInstance;
  authentication?: AxiosInstance;
  plans?: PlansContextClient;
  fluids?: FluidsContextClient;
  dashboards?: DashboardsContextClient;
  chinabook?: ChinabookContextClient;
  reports?: ReportsContextClient;
  incidents?: IncidentsContextClient;
  medicine?: MedicineContextClient;
  inventory?: InventoryContextClient;
  toggles?: TogglesContextClient;
}

export const RestClientsContext = React.createContext<RestClientsContext>({
  atlas: undefined,
  tasks: undefined,
  nexus: undefined,
  observations: undefined,
  healthMeasurements: undefined,
  notifications: undefined,
  records: undefined,
  compass: undefined,
  authentication: undefined,
  plans: undefined,
  fluids: undefined,
  dashboards: undefined,
  chinabook: undefined,
  reports: undefined,
  incidents: undefined,
  medicine: undefined,
  inventory: undefined,
  toggles: undefined,
});
