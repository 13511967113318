import React, { memo, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import makeStyles from '@mui/styles/makeStyles';
import ApartmentAvatar from './ApartmentAvatar';
import { Location } from './ApartmentList.interface';
import { Collapse, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { getThemeSpacingFromUnit } from '@sekoia/shared/utils/styleHelper';
import { Theme } from '@mui/material';
import { sortArrayOfObjects } from '@sekoia/shared/utils/sortHelpers';

type Props = {
  locationsWithResidents: Location;
  availableText: string;
  onClick: (apartment: Location) => void;
  avatarSize?: string;
  apartmentListHeightJss?: string;
  theme: Theme;
};

function ApartmentList(props: Props) {
  const useStyles = makeStyles(() => ({
    list: {
      width: '100%',
      maxHeight: (props: Props) =>
        props.apartmentListHeightJss ? props.apartmentListHeightJss : `calc(100vh - ${48}px)`,
      position: 'relative',
      overflow: 'auto',
      overflowScrolling: 'touch',
      WebkitOverflowScrolling: 'touch',
      paddingTop: 0,
    },
    listSubHeader: {
      cursor: 'pointer',
      backgroundColor: props.theme.palette.grey[100],
      lineHeight: props.theme.spacing(5),
      height: props.theme.spacing(5),
    },
  }));
  const classes = useStyles(props);
  const [selectedSubHeader, setSelectedSubHeader] = useState<string[]>([]);

  function apartmentChosen(apartment: Location) {
    props.onClick(apartment);
  }
  const onSubHeaderClick = (globalId: string) => {
    const newArray = [...selectedSubHeader];
    if (newArray.includes(globalId)) {
      const i = newArray.indexOf(globalId);
      newArray.splice(i, 1);
    } else {
      newArray.push(globalId);
    }
    setSelectedSubHeader(newArray);
  };

  function renderApartments(apartments: Location[]) {
    return apartments.map((apartment) => {
      return (
        <ListItem button onClick={() => apartmentChosen(apartment)} key={`${apartment.globalId}`}>
          <ListItemIcon>
            <ApartmentAvatar backgroundColor={props.theme.palette.primary.main} />
          </ListItemIcon>
          <ListItemText
            primary={apartment.name}
            secondary={
              apartment.citizens && apartment.citizens.length > 0 ? (
                sortArrayOfObjects(apartment.citizens, 'firstname')
                  .map((resident) => `${resident.firstname} ${resident.lastname}`)
                  .join(', ')
              ) : (
                <Typography color="error">{props.availableText}</Typography>
              )
            }
          />
        </ListItem>
      );
    });
  }

  function renderDepartment() {
    if (!props.locationsWithResidents.children) return null;
    return props.locationsWithResidents.children
      .filter((organization) => organization)
      .map((organization) => {
        return sortArrayOfObjects(organization.children, 'name').map((department) => {
          return (
            <div key={`${organization.globalId}${department.globalId}`}>
              <ListSubheader className={classes.listSubHeader} onClick={() => onSubHeaderClick(department.globalId)}>
                <React.Fragment>
                  <div>{department.name}</div>{' '}
                  <div
                    style={{ position: 'absolute', right: getThemeSpacingFromUnit(4), top: 0, alignItems: 'center' }}
                  >
                    {organization.name}{' '}
                  </div>
                  <div
                    style={{ position: 'absolute', right: getThemeSpacingFromUnit(1), top: getThemeSpacingFromUnit(1) }}
                  >
                    {selectedSubHeader.includes(department.globalId) ? <ExpandLess /> : <ExpandMore />}{' '}
                  </div>
                </React.Fragment>
              </ListSubheader>
              <Collapse in={selectedSubHeader.includes(department.globalId)} timeout="auto" unmountOnExit>
                {renderApartments(sortArrayOfObjects(department.children, 'name'))}
              </Collapse>
            </div>
          );
        });
      });
  }

  return (
    <React.Fragment>
      <List className={classes.list}>
        <li>
          <ul style={{ paddingInlineStart: 0 }}>{renderDepartment()}</ul>
        </li>
      </List>
    </React.Fragment>
  );
}

export default memo(ApartmentList);
