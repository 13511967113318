import useCompassClient from '@sekoia/shared/hooks/common/useCompassClient';
import { useTokenContext } from 'context/Token';
import { useCallback } from 'react';
import CacheKeys from 'constants/CacheKeys';
import { sortArrayOfObjects } from 'utils/sortHelpers';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';

interface AffiliatedOrganization {
  id: string;
  name: string;
  displayName: string;
}

export const useFetchAffiliatedOrganizations = () => {
  const compassClient = useCompassClient();
  const { customerId } = useTokenContext();

  const getAffiliatedOrganizations = useCallback(async () => {
    const response = await compassClient.get<AffiliatedOrganization[]>(
      `/api/directory/customers/${customerId}/affiliated-organizations`,
    );

    return sortArrayOfObjects(response.data, 'name');
  }, [compassClient, customerId]);

  return useQueryWithRetry({
    queryKey: [CacheKeys.AFFILIATED_ORGANIZATIONS],
    queryFn: () => getAffiliatedOrganizations(),
  });
};
