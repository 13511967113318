import React, { useMemo } from 'react';
import { useTokenContext } from 'context/Token';
import { CompassClient } from 'requests/compassClient';
import { AuthenticationClient } from 'requests/authenticationClient';
import { NexusContextClient } from '@sekoia/shared/contexts/RestClients/Clients/NexusContextClient';
import { AtlasContextClient } from '@sekoia/shared/contexts/RestClients/Clients/AtlasContextClient';
import { HealthMeasurementsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/HealthMeasurementsContextClient';
import { RecordsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/RecordsContextClient';
import { TasksContextClient } from '@sekoia/shared/contexts/RestClients/Clients/TasksContextClient';
import { ObservationsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/ObservationsContextClient';
import { RestClientsContext } from '@sekoia/shared/contexts/RestClients/RestClientsContext';
import { NotificationsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/NotificationsContextClient';
import { PlansContextClient } from '@sekoia/shared/contexts/RestClients/Clients/PlansContextClient';
import { FluidsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/FluidsContextClient';
import { DashboardsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/DashboardsContextClient';
import { ChinabookContextClient } from '@sekoia/shared/contexts/RestClients/Clients/ChinabookContextClient';
import { ReportsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/ReportsContextClient';
import { IncidentsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/IncidentsContextClient';
import { MedicineContextClient } from '@sekoia/shared/contexts/RestClients/Clients/MedicineContextClient';
import { InventoryContextClient } from '@sekoia/shared/contexts/RestClients/Clients/InventoryContextClient';
import { optionsTransformer } from '@sekoia/shared/requests/nswagBaseClients/baseClient';
import { Authentication } from 'utils/Authentication/Authentication';

export const RestClientsProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const profile = useTokenContext();

  const providerValue = useMemo(() => {
    CompassClient.createInstance(profile.customerUrl, profile.customerId);
    AuthenticationClient.createInstance(profile.customerId);

    optionsTransformer.transformFunc = async (options: RequestInit): Promise<RequestInit> => {
      const token = await Authentication.Instance.getToken();
      const profileInfo = await Authentication.Instance.getProfileInformationFromToken();
      let headers = new Headers(options.headers);

      headers.set('Authorization', `Bearer ${token}`);
      headers.set('sekoia.customer_id', profileInfo?.customerId || '');

      options.headers = headers;

      return Promise.resolve(options);
    };

    return {
      compass: CompassClient.Instance,
      atlas: new AtlasContextClient(),
      authentication: AuthenticationClient.Instance,
      records: new RecordsContextClient(),
      nexus: new NexusContextClient(),
      tasks: new TasksContextClient(),
      observations: new ObservationsContextClient(),
      healthMeasurements: new HealthMeasurementsContextClient(),
      notifications: new NotificationsContextClient(),
      plans: new PlansContextClient(),
      fluids: new FluidsContextClient(),
      dashboards: new DashboardsContextClient(),
      chinabook: new ChinabookContextClient(),
      reports: new ReportsContextClient(),
      incidents: new IncidentsContextClient(),
      medicine: new MedicineContextClient(),
      inventory: new InventoryContextClient(),
    };
  }, [profile]);

  return <RestClientsContext.Provider value={providerValue}>{props.children}</RestClientsContext.Provider>;
};
